import 'takeover/takeover.js';
import lazySizes from 'lazysizes';

lazySizes.cfg.iframeLoadMode = 1;

// Hack to replace burst-icon-with-exclamation-point with burst-icon-NO-exclamation-point
// for DOE icon when number of events is also displayed.
$(document).ready(() => {
  const $span = $('.js_days_count');
  if ($span.length) {
    $span.hide();
    window.setTimeout(() => {
      if ($span.html() !== '!') {
        const val = $span.prev('.icon').find('use').attr('xlink:href');
        $span.prev('.icon').find('use').attr(
          'xlink:href',
          val.replace('tk-icon-burst', 'tk-icon-burst-blank').replace('icon_burst', 'tk-icon-burst-blank')
        );
        $span.show();
      }
    }, 500);
  }

  // Google Analytics GA4 - custom event tracking
  $('a').filter(function () {
    return $(this).text().includes('Donate');
  }).click(() => {
    if (window.gtag) {
      window.gtag('event', 'donate_bttn', {
        event_category: 'Donation Button Clicked',
        event_label: $(document).attr('title'),
        transport_type: 'beacon'
      });
    }
  });
});
